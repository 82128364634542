import React, { useContext, useState, useEffect } from 'react'
import { ParametrosInterface } from '../../ImportBackend/Interfaces/ParametrosInterfaces'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InputText from '../../DevComponents/InputText';
import PesquisarTabela from '../../DevComponents/PesquisarTabela';
import { PerfilInterface } from '../../ImportBackend/Interfaces/PerfilInterfaces';
import ClsValidacao from '../../Utils/ClsValidacao';
import ClsUtils from 'zlib-utils';
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces';
import { MensagemTipo } from '../../GlobalStates/MensagemState';

export default function Parametros () {

  const abortController: AbortController = new AbortController()

  const contextoGlobal = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { mensagemState, setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const clsApi = new BackEndAPI()

  const ResetDados: ParametrosInterface = {
    idParametro: 0,
    preCadastroSiteRedirecionamento: '',
    preCadastroClienteIdPerfilPadrao: 0,
    preCadastroSessoesSimultaneas: 0,
    preCadastroAtivacaoAutomatica: false,
    emailNome: '',
    emailEmail: '',
    emailSenha: '',
    emailHost: '',
    emailPorta: ''
  }

  const [dados, setDados] = useState<ParametrosInterface>( ResetDados )
  const [erros, setErros] = useState( {} )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'preCadastroSiteRedirecionamento', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'preCadastroClienteIdPerfilPadrao', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'preCadastroSessoesSimultaneas', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'emailNome', dados, erros, retorno )
    retorno = clsValidacao.eEmail( 'emailEmail', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'emailSenha', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'emailHost', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'emailPorta', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const btFechar = () => {
    navigate( '/' )
  }

  const btConfirmar = () => {

    if ( validarDados() ) {

      const mutation: string = `
        updateParametros (dados: ${( new ClsUtils() ).ConverterEmGql( dados )} ) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'updateParametros', 'Atualizando Parâmetros...', contextoGlobal ).then( rs => {

        if ( rs.ok ) {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            mensagem: 'Parâmetros Atualizados!',
            tipo: MensagemTipo.Info,
            exibirBotao: true
          } )

          navigate( '/' )

        }

      } )

    }
  }

  useEffect( () => {

    const query: string = `
      getParametros {
        idParametro
        preCadastroSiteRedirecionamento
        preCadastroClienteIdPerfilPadrao
        preCadastroSessoesSimultaneas
        preCadastroAtivacaoAutomatica
        emailNome
        emailEmail
        emailSenha
        emailHost
        emailPorta
      }
    `

    clsApi.query<ParametrosInterface>( query, 'getParametros', 'Pesquisando Parâmetros...', contextoGlobal, abortController ).then( rs => {

      setMensagemState( { ...mensagemState, exibir: false } )
      setDados( rs )

    } ).catch( e => {
      console.log( e )
    } )

    return () => {

      abortController.abort()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Parâmetros
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={dados}
                field='preCadastroSiteRedirecionamento'
                label='Site Redirecionamento Pré Cadastro'
                setState={setDados}
                erros={erros}
                maxLength={255}
              />

            </Grid>

            <Grid item xs={12} sm={6}>

              <PesquisarTabela<PerfilInterface>
                setState={setDados}
                field='idPerfil'
                fieldSet='preCadastroClienteIdPerfilPadrao'
                label='Perfil Cliente Padrão'
                dados={dados}
                campoQueryPesquisaID='idPerfil'
                campoQueryPesquisa='pesquisa'
                camposRetornoQueryPesquisa='{idPerfil, descricao}'
                campoLabelQueryPesquisa='descricao'
                nomeQueryPesquisa='getPerfis'
                nomeQueryPesquisaID='getPerfilPorId'
                mensagemPesquisa='Procurando Perfis...'
                erros={erros}
                pesquisarTudoAoIniciar
              />

            </Grid>

            <Grid item xs={6} sm={3} sx={{ mt: 4, pl: { sm: 1 } }}>
              <InputText
                dados={dados}
                field='preCadastroAtivacaoAutomatica'
                label='Ativação Automática'
                setState={setDados}
                tipo='checkbox'
                erros={erros}
              />
            </Grid>

            <Grid item xs={6} sm={3} sx={{ pl: { sm: 1 } }} >

              <InputText
                dados={dados}
                field='preCadastroSessoesSimultaneas'
                label='Sessões Simultâneas'
                tipo='number'
                setState={setDados}
                erros={erros}
                maxLength={3}
              />

            </Grid>

            <Grid item xs={12} >

              <InputText
                dados={dados}
                field='emailNome'
                label='Nome para e-mail'
                setState={setDados}
                erros={erros}
                maxLength={50}
              />

            </Grid>

            <Grid item xs={12} >

              <InputText
                dados={dados}
                field='emailEmail'
                label='Endereço do e-mail'
                setState={setDados}
                erros={erros}
                maxLength={255}
              />

            </Grid>

            <Grid item xs={12} sm={5} >

              <InputText
                dados={dados}
                field='emailSenha'
                label='Senha do e-mail'
                setState={setDados}
                erros={erros}
                maxLength={25}
              />

            </Grid>

            <Grid item xs={12} sm={5} sx={{ pl: { sm: 1 } }} >

              <InputText
                dados={dados}
                field='emailHost'
                label='Host do servidor de e-mail'
                setState={setDados}
                erros={erros}
                maxLength={25}
              />

            </Grid>

            <Grid item xs={12} sm={2} sx={{ pl: { sm: 1 } }} >

              <InputText
                dados={dados}
                field='emailPorta'
                label='Porta'
                setState={setDados}
                erros={erros}
                maxLength={5}
              />

            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmar()}>Confirmar</Button>
            </Grid>

          </Grid>

        </Paper>
      </Container>

    </>
  )

}