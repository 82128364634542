import { CssBaseline, ThemeProvider } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { THEME } from '../Config/Theme'
import { ContextoGlobal } from '../GlobalStates/ContextoGlobal'
import useLayoutState from '../GlobalStates/LayoutState'
import Mensagem from '../DevComponents/Mensagem'
import BottomBar from './BottomBar'
import TopAppBar from './TopAppBar'

import Menu from './Menu'
import Condicional from './Condicional'
import Login from '../View/Controller/Login'
import useMensagemState from '../GlobalStates/MensagemState'
import useLoginState from '../GlobalStates/LoginState'
import { useApolloClient } from '@apollo/client'
import PreCadastroCliente from '../View/PreCadastro/PreCadastroCliente'
import { EMDESENVOLVIMENTO } from '../ImportBackend/Config/emDesenvolvimento'
import ResetSenha from '../View/Controller/ResetSenha'
import { DESATIVAR_TELA_CADASTRO_EM_DESENVOLVIMENTO } from '../Config/ConfigFrontEnd'

export default function Layout () {

  const { layoutState, setLayoutState } = useLayoutState()
  const { mensagemState, setMensagemState } = useMensagemState()
  const { loginState, setLoginState } = useLoginState()

  return (
    <>
      <ContextoGlobal.Provider value={{
        layoutState: layoutState,
        setLayoutState: setLayoutState,
        mensagemState: mensagemState,
        setMensagemState: setMensagemState,
        loginState: loginState,
        setLoginState: setLoginState,
        apolloClient: useApolloClient()
      }}>
        <CssBaseline />
        <ThemeProvider theme={THEME}>

          <Mensagem />

          <Condicional condicao={loginState.logado}>

            <TopAppBar />
            <Outlet />
            <BottomBar></BottomBar>

            <Menu></Menu>

          </Condicional>

          <Condicional condicao={EMDESENVOLVIMENTO && DESATIVAR_TELA_CADASTRO_EM_DESENVOLVIMENTO && !loginState.logado}>
            <Login />
          </Condicional>

          <Condicional condicao={( !EMDESENVOLVIMENTO || ( EMDESENVOLVIMENTO && !DESATIVAR_TELA_CADASTRO_EM_DESENVOLVIMENTO ) ) && !loginState.logado}>

            <Condicional condicao={
              !loginState.logado
              && !window.location.href.includes( '/Login' )
              && !window.location.href.includes( '/ResetSenha' )
            }>
              <PreCadastroCliente />
            </Condicional>

            <Condicional condicao={
              !loginState.logado
              && window.location.href.includes( '/Login' )
            }>
              <Login />
            </Condicional>

            <Condicional condicao={
              !loginState.logado
              && window.location.href.includes( '/ResetSenha' )
            }>
              <ResetSenha />
            </Condicional>

          </Condicional>

        </ThemeProvider>

      </ContextoGlobal.Provider>

    </>
  )
}