import React, { useContext, useEffect, useState } from 'react'
import { Container, Grid, Paper, Typography, Link } from '@mui/material';
import InputText from '../../DevComponents/InputText';
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal';
import Condicional from '../../Layout/Condicional';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import ClsValidacao from '../../Utils/ClsValidacao';
import ClsCep from '../../Utils/ClsCep';
import BackEndAPI from '../../Services/BackEndAPI';
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces';
import { MensagemTipo } from '../../GlobalStates/MensagemState';
import { clsUtils } from 'zlib-utils';
import TermoAceite from './TermoAceite';
import { ParametrosInterface } from '../../ImportBackend/Interfaces/ParametrosInterfaces';
import { ID_PARAMETRO_PADRAO } from '../../ImportBackend/Config/emDesenvolvimento';
import { PreCadastroClienteInputInterface } from '../../ImportBackend/Interfaces/ClienteInterfaces';
import { useNavigate } from 'react-router-dom';

export default function PreCadastroCliente () {

  const abortController: AbortController = new AbortController()

  const [cadastroRealizado, setCadastroRealizado] = useState( false )
  const clsApi = new BackEndAPI()

  const [exibirSenhaState, setExibirSenha] = useState( false )

  const ResetDados: PreCadastroClienteInputInterface =
  {
    nome: '',
    cep: '',
    endereco: '',
    bairro: '',
    cidade: '',
    uf: '',
    rg: '',
    cpf: '',
    email: '',
    whatsAPP: '',
    fone: '',
    senha: '',
    confirmarSenha: '',
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<PreCadastroClienteInputInterface>( ResetDados )
  const [erros, setErros] = useState( {} )

  const [urlPosCadastro, setUrlPosCadastro] = useState( '' )

  const nav = useNavigate()

  const alterarCPF = ( cpf: string ) => {

    const clsValidacao = new ClsValidacao()

    setDados( { ...dados, cpf: cpf } )

    if ( cpf.length === 14 && clsValidacao.eCPF( 'cpf', { cpf: cpf }, {}, true, false ) ) {

      const query: string = `
        cpfCadastrado(cpf: "${cpf}") {
          ok
          mensagem
        }
      `

      clsApi.query<RespostaPadraoInterface>( query, 'cpfCadastrado', 'Verificando Versao...', contexto, abortController ).then( rs => {

        if ( rs.ok ) {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            exibirBotao: true,
            mensagem: 'Redirecionando para recuperar senha!',
            tipo: MensagemTipo.Warning,
            titulo: 'CPF Já Cadastrado!'
          } )

          nav( '/ResetSenha', { state: { cpf: cpf } } )

        }

      } )

      return () => {

        abortController.abort()

      }

    }

  }

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    const clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eCPF( 'cpf', dados, erros, retorno, false )
    retorno = clsValidacao.naoVazio( 'nome', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'cep', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'endereco', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'bairro', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'cidade', dados, erros, retorno )
    retorno = clsValidacao.eUF( 'uf', dados, erros, retorno, false )
    retorno = clsValidacao.eEmail( 'email', dados, erros, retorno, true )
    retorno = clsValidacao.eTelefone( 'whatsAPP', dados, erros, retorno, true )
    retorno = clsValidacao.eTelefone( 'fone', dados, erros, retorno, true )

    if ( dados.senha.length < 6 || dados.senha !== dados.confirmarSenha ) {
      erros.senha = 'Mínimo de 6 posições'
      erros.confirmarSenha = 'Confirme a Senha Digitada'
      retorno = false
    }

    setErros( erros )

    return retorno

  }

  const alterarCep = ( novoCep: any ): void => {
    if ( novoCep.length === 10 ) {

      let clsCep: ClsCep = new ClsCep()

      clsCep.buscarCep( setMensagemState, novoCep ).then( rs => {
        if ( rs ) {

          setDados( {
            ...dados,
            cep: novoCep,
            bairro: rs.bairro,
            cidade: rs.localidade,
            endereco: rs.logradouro,
            uf: rs.uf
          } )

        } else {
          setDados( { ...dados, cep: novoCep } )
        }
      } )

    }
  }

  const btConfirmar = () => {
    if ( validarDados() ) {

      const mutation: string = `
        preCadastro(dados: ${clsUtils.ConverterEmGql( dados )}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'preCadastro', 'Realizando o Cadastro...', contexto ).then( rs => {

        if ( rs.ok ) {

          setMensagemState( {
            ...mensagemState,
            titulo: 'Confirmado!',
            exibir: true,
            mensagem: 'Cadastro Realizado com Sucesso!',
            tipo: MensagemTipo.Info,
            exibirBotao: true
          } )

          setDados( ResetDados )
          setCadastroRealizado( true )

          window.location.href = urlPosCadastro

        } else {

          setMensagemState( {
            ...mensagemState,
            titulo: 'Erro ao Cadastrar Acesso! Consulte Suporte!',
            exibir: true,
            mensagem: rs.mensagem,
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )

        }

      } )

    } else {
      console.log( erros )
    }
  }

  useEffect( () => {

    const query: string = `
      getParametros {
        idParametro
        preCadastroSiteRedirecionamento
      }
    `

    clsApi.query<ParametrosInterface>( query, 'getParametros', 'Pesquisando Parâmetros...', contexto, abortController ).then( rs => {

      setMensagemState( { ...mensagemState, exibir: false } )

      if ( rs && rs.idParametro === ID_PARAMETRO_PADRAO ) {
        setUrlPosCadastro( rs.preCadastroSiteRedirecionamento )
      }

    } )

    return () => {

      abortController.abort()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )


  const btCancelar = () => {
  }

  const handleExibirSenha = () => {
    setExibirSenha( !exibirSenhaState )
  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mb: 3 }}>
              <img src={'imagens/'.concat( process.env.REACT_APP_PASTA_IMAGENS as string ).concat( '/logo.png' )} alt={process.env.REACT_APP_TITULO} width={300} />
            </Grid>

            <Condicional condicao={!cadastroRealizado}>

              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography component="h5" variant="h5" align="left">
                  Cadastro Acesso Wifi
                  <Typography variant="body2" gutterBottom>
                    Forneça todos os dados solicitados
                  </Typography>
                </Typography>

              </Grid>

              <Grid item xs={12} sm={4} md={3}>

                <InputText
                  dados={dados}
                  field='cpf'
                  label='CPF'
                  setState={setDados}
                  erros={erros}
                  maxLength={14}
                  mask='cpf'
                  onChange={( cpf: string ) => alterarCPF( cpf )}
                />

              </Grid>

              <Grid item xs={12} sm={8} md={9} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='nome'
                  label='Nome'
                  setState={setDados}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12} sm={3} md={2}>

                <InputText
                  dados={dados}
                  field='cep'
                  label='Cep'
                  setState={setDados}
                  erros={erros}
                  mask='cep'
                  onChange={( novoCep ) => alterarCep( novoCep )}
                />

              </Grid>

              <Grid item xs={12} sm={9} md={10} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='endereco'
                  label='Endereço'
                  setState={setDados}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12} sm={5}>

                <InputText
                  dados={dados}
                  field='bairro'
                  label='Bairro'
                  setState={setDados}
                  erros={erros}
                  maxLength={30}
                />

              </Grid>

              <Grid item xs={12} sm={5} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='cidade'
                  label='Cidade'
                  setState={setDados}
                  erros={erros}
                  maxLength={35}
                />

              </Grid>

              <Grid item xs={12} sm={2} sx={{ pl: { sm: 1 } }}>

                <InputText
                  dados={dados}
                  field='uf'
                  label='UF'
                  setState={setDados}
                  erros={erros}
                  mask='uf'
                  tipo='uppercase'
                />

              </Grid>

              <Grid item xs={12} >

                <InputText
                  dados={dados}
                  field='email'
                  label='Email'
                  setState={setDados}
                  erros={erros}
                  maxLength={255}
                />

              </Grid>

              <Grid item xs={12} sm={12} md={4} >

                <InputText
                  dados={dados}
                  field='rg'
                  label='RG'
                  setState={setDados}
                  erros={erros}
                  maxLength={25}
                />

              </Grid>

              <Grid item xs={12} sm={6} md={4} sx={{ pl: { md: 1 } }}>
                <InputText
                  dados={dados}
                  field='whatsAPP'
                  label='Whats App'
                  setState={setDados}
                  erros={erros}
                  maxLength={15}
                  mask='tel'
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} sx={{ pl: { sm: 1 } }}>
                <InputText
                  dados={dados}
                  field='fone'
                  label='Fone'
                  setState={setDados}
                  erros={erros}
                  maxLength={15}
                  mask='tel'
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>

                <InputText
                  type={exibirSenhaState ? "text" : "password"}
                  onClickIconeEnd={handleExibirSenha}
                  iconeEnd='visibility'
                  dados={dados}
                  field='senha'
                  label='Senha'
                  setState={setDados}
                  erros={erros}
                  maxLength={15}
                />

              </Grid>

              <Grid item xs={12} sm={6} md={4} sx={{ pl: { sm: 1 } }}>

                <InputText
                  type={exibirSenhaState ? "text" : "password"}
                  onClickIconeEnd={handleExibirSenha}
                  iconeEnd='visibility'
                  dados={dados}
                  field='confirmarSenha'
                  label='Confirmar Senha'
                  setState={setDados}
                  erros={erros}
                  maxLength={15}
                />

              </Grid>

              <Grid item xs={12}>
                <TermoAceite />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>

                <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btConfirmar()}>Confirmar</Button>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={cadastroRealizado}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <Typography component="h5" variant="h5" align="left">
                  Cadastro Realizado com Sucesso!
                  <Typography variant="body2" gutterBottom>
                    Redirecionando para Login na Rede!
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Caso o redirecionamento não seja feito de forma automática, clique no link abaixo.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Obrigado!
                  </Typography>
                </Typography>

              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around', mb: 3, mt: 5 }}>
                <Link href={urlPosCadastro} underline="none">
                  Clique Aqui e Faça Login no WIFI
                </Link>
              </Grid>
            </Condicional>

          </Grid>
        </Paper>

      </Container>
    </>
  )
}